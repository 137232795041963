import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Header, SEO, Wrapper } from '../components'

const Root = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  div {
    flex: 1 0 100%;
    text-align: center;
  }
  h1 {
    font-size: 100px;
  }
`

const NotFound = ({ location }) => (
  <>
    <SEO pathname={location.pathname} locale="en-us" title="404" canonical={false} />
    <Wrapper>
      <Header dark />
    </Wrapper>
    <Root>
      <div>
        <h1>404</h1>
      </div>
    </Root>
  </>
)

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFound
